import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MobileFeature = makeShortcode("MobileFeature");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MobileFeature legacyPhoneImage="objectsPhoneFull" mdxType="MobileFeature">
      <h1>{`Quartz Objects`}</h1>
      <p>{`Objects was a project to bring augmented reality to news stories in the Quartz app.`}</p>
      <p>{`The Quartz app was already a bit of a playground for experimentation — with a conversational interface and news stories accompanied by quirky animated gifs — so it was the perfect place to experiment with ARKit.`}</p>
      <p>{`Ultimately we launched an AR journalism series called `}<a parentName="p" {...{
          "href": "https://qz.com/se/the-2050-project/"
        }}>{`The 2050 Project`}</a>{` that used the Quartz app to showcase innovative buildings from cities around the globe.`}</p>
      <blockquote {...{
        "className": "twitter-tweet",
        "data-dnt": "true"
      }}><p parentName="blockquote" {...{
          "lang": "en",
          "dir": "ltr"
        }}>{`Our new series, 2050 Project, launches tomorrow and features bold architecture in eight international cities. These uniquely designed buildings represent solutions to some of the challenges of urban living. `}<a parentName="p" {...{
            "href": "https://twitter.com/hashtag/2050Project?src=hash&ref_src=twsrc%5Etfw"
          }}>{`#2050Project`}</a>{` `}<a parentName="p" {...{
            "href": "https://t.co/8b4ctUkx56"
          }}>{`pic.twitter.com/8b4ctUkx56`}</a></p>{`— Quartz (@qz) `}<a parentName="blockquote" {...{
          "href": "https://twitter.com/qz/status/1100464750041944064"
        }}>{`February 26, 2019`}</a></blockquote>
    </MobileFeature>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      